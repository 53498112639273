import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

const Wrapper = styled.div`
  margin-bottom: 70px;
`;

const Number = styled.span``;

const Line = styled.span`
  height: 1px;
  display: block;
  width: 58px;
  margin: 0 10px;
  position: relative;

  &::after {
    opacity: 0;
    transition: all 200ms ease-in;
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 0%;
    bottom: -1px;
    left: 0;
    background: #979797;
    transition: width 100ms ease-in;
  }
`;

const Text = styled.span`
  color: #242424;
`;

const Item = styled.div`
  display: flex;
  opacity: 0.5;
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
  cursor: pointer;
  align-items: flex-end;
  transition: color, opacity, font-weight 200ms linear;

  &.active {
    opacity: 1;
    cursor: default;
    ${Text} {
      color: #0276f1;
    }
    ${Line} {
      &::after {
        opacity: 1;
        width: 100%;
      }
    }
  }

  & + & {
    margin-top: 50px;
  }
`;

function TabHeadingsVertical({ className, items, activeId, onChange }) {
  return (
    <Wrapper className={className}>
      {items.map((item, i) => (
        <Item
          className={classNames({ active: item.id === activeId })}
          onClick={() => {
            onChange(item.id);
          }}
          key={item.id}
        >
          <Number>{`${(i + 1).toString().padStart(2, 0)}.`}</Number>
          <Line />
          <Text>{item.title}</Text>
        </Item>
      ))}
    </Wrapper>
  );
}

TabHeadingsVertical.defaultProps = {
  className: null,
};

TabHeadingsVertical.propTypes = {
  className: PropTypes.string,
  activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
};

export default TabHeadingsVertical;
