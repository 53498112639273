import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { dark, light } from 'themes';
import Layout from 'components/Layout';
import Paragraph from 'components/Paragraph';
import Heading from 'components/Heading';
import { HEADER_HEIGHT } from 'components/Header';
import { Container, Row, Col } from 'react-grid-system';
import breakpoints from 'utils/breakpoints';
import IntroImage from 'components/Products/TrustIdentity/IntroImage';
import DigitalOnboardingContent from 'components/Products/TrustIdentity/DigitalOnboardingContent';
import IdentityVerificationContent from 'components/Products/TrustIdentity/IdentityVerificationContent';
import WhyChooseUsContent from 'components/Products/TrustIdentity/WhyChooseUsContent';
import CaseStudiesContent from 'components/Products/TrustIdentity/CaseStudiesContent';
import SimpleList from 'components/ItemList/Simple';
import intro from 'contents/products/trust-identity/intro/intro.json';

const Intro = styled.section`
  padding-top: ${HEADER_HEIGHT + 70}px;
  padding-bottom: 50px;
  text-align: center;
  @media screen and (min-width: ${breakpoints.md}) {
    padding-bottom: 70px;
    text-align: left;
  }
`;

const Section = styled.section`
  padding: 80px 0 120px;
  background: #fff;

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 70px 0;
  }
`;

const BlueSection = styled(Section)`
  background: #f4f9ff;
`;

const DigitalOnboarding = styled(BlueSection)``;

const IdentityVerifycation = styled(Section)``;

const StyledIntroImage = styled(IntroImage)`
  width: 100%;
`;

const WhyChooseUs = styled(Section)`
  padding-top: 0;

  @media screen and (min-width: ${breakpoints.md}) {
    padding-top: 0;
    padding-bottom: 140px;
  }
`;
const CaseStudies = styled(BlueSection)`
  @media screen and (min-width: ${breakpoints.md}) {
    padding-bottom: 140px;
  }
`;

const StyledSimpleList = styled(SimpleList)``;

function TrustIdentityProductPage({ className }) {
  return (
    <Layout
      headerTheme={light}
      className={className}
      fullSize
      pageTitle="Trust Identity"
    >
      <ThemeProvider theme={dark}>
        <Intro>
          <Container>
            <Row justify="center">
              <Col md={6}>
                <StyledIntroImage />
              </Col>
              <Col xs={10} md={6}>
                <Heading title={intro.title} />
                <Paragraph>{intro.description}</Paragraph>
                <StyledSimpleList items={intro.list} />
              </Col>
            </Row>
          </Container>
        </Intro>
      </ThemeProvider>
      <DigitalOnboarding>
        <DigitalOnboardingContent />
      </DigitalOnboarding>
      <IdentityVerifycation>
        <IdentityVerificationContent />
      </IdentityVerifycation>
      <WhyChooseUs>
        <Container>
          <WhyChooseUsContent />
        </Container>
      </WhyChooseUs>
      <CaseStudies>
        <CaseStudiesContent />
      </CaseStudies>
    </Layout>
  );
}

TrustIdentityProductPage.defaultProps = {
  className: null,
};

TrustIdentityProductPage.propTypes = {
  className: PropTypes.string,
};

export default TrustIdentityProductPage;
