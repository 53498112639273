import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TabHeading = styled.div`
  width: 290px;
  padding: 30px 0;
  text-align: center;
  color: #242424;
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
  cursor: pointer;
  border-bottom: 1px solid #dadada;
  box-sizing: border-box;
  transition: color 200ms linear;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 0%;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    background: #0276f1;
    transition: width 100ms ease-in;
  }

  &.active {
    cursor: default;
    color: #0276f1;
    &:after {
      width: 100%;
    }
  }
`;

function TabHeadings({ className, items, activeId, onChange }) {
  return (
    <Wrapper className={className}>
      {items.map(item => (
        <TabHeading
          className={classNames({ active: item.id === activeId })}
          onClick={() => {
            onChange(item.id);
          }}
          key={item.id}
        >
          {item.title}
        </TabHeading>
      ))}
    </Wrapper>
  );
}

TabHeadings.defaultProps = {
  className: null,
};

TabHeadings.propTypes = {
  className: PropTypes.string,
  activeId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.string,
    })
  ).isRequired,
};

export default TabHeadings;
