import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-grid-system';
import styled from 'styled-components';
import Img from 'components/Img';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled(Container)`
  padding: 50px 0;
  min-height: 420px;
`;

const Title = styled.h4`
  display: none;
  @media screen and (min-width: ${breakpoints.md}) {
    color: #1c2f45;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
  }
`;

const Content = styled.article`
  color: #1c2f45;
`;

const Description = styled.div`
  p {
    font-size: 16px;
    line-height: 28px;
  }

  ul {
    padding-left: 19px;
    padding-right: 0;
  }

  li {
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
    font-size: 14px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ImgWrap = styled.div`
  display: flex;
  @media screen and (min-width: ${breakpoints.md}) {
    justify-content: flex-start;
  }
`;

const LeftImgWrap = styled(ImgWrap)`
  @media screen and (min-width: ${breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const StyledImg = styled(Img)`
  @media screen and (min-width: ${breakpoints.md}) {
    max-width: 100%;
  }
`;

function TabContent({ className, title, html, image, imageLeft }) {
  if (imageLeft) {
    return (
      <Wrapper className={className}>
        <Row align="center" justify="center">
          <Col md={4}>
            <LeftImgWrap>
              <StyledImg {...image} />
            </LeftImgWrap>
          </Col>
          <Col md={5} offset={{ md: 1 }}>
            <Content>
              <Title>{title}</Title>
              <Description dangerouslySetInnerHTML={{ __html: html }} />
            </Content>
          </Col>
        </Row>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className}>
      <Row align="center" justify="center">
        <Col md={4} push={{ md: 6 }}>
          <ImgWrap>
            <StyledImg {...image} />
          </ImgWrap>
        </Col>
        <Col md={5} pull={{ md: 4 }}>
          <Content>
            <Title>{title}</Title>
            <Description dangerouslySetInnerHTML={{ __html: html }} />
          </Content>
        </Col>
      </Row>
    </Wrapper>
  );
}

TabContent.defaultProps = {
  className: null,
  imageLeft: false,
};

TabContent.propTypes = {
  className: PropTypes.string,
  imageLeft: PropTypes.bool,
  title: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  image: PropTypes.shape({}).isRequired,
};

export default TabContent;
