import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Heading from 'components/Heading';
import SubHeading from 'components/Heading/SubHeading';
import Tab from 'components/Tab';
import { Container, Row, Col } from 'react-grid-system';
import TabHeadingsVertical from 'components/Tab/TabHeadingsVertical';

const Content = styled.div`
  h4 {
    margin-top: 0;
  }
`;
const Text = styled.div`
  p {
    color: #1c2f45;
    font-family: 'Be Vietnam';
    font-size: 16px;
    line-height: 28px;
  }
`;

function useCaseStudiesData() {
  const digitalOnboardingData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: {
            glob: "**/contents/products/trust-identity/case-studies/*.md"
          }
        }
      ) {
        nodes {
          id
          frontmatter {
            title
            image {
              publicURL
            }
          }
          html
        }
      }
    }
  `);
  return useMemo(() => {
    return digitalOnboardingData.allMarkdownRemark.nodes.map(item => ({
      title: item.frontmatter.title,
      id: item.id,
      content: (
        <Content>
          <SubHeading>{item.frontmatter.title}</SubHeading>
          <Text dangerouslySetInnerHTML={{ __html: item.html }} />
        </Content>
      ),
    }));
  }, [digitalOnboardingData]);
}

function CaseStudies() {
  const digitalOnboardingTabItems = useCaseStudiesData();

  return (
    <Container>
      <Row justify="center" align="start">
        <Col lg={10}>
          <Heading title="Contoh Penggunaan" />
          <Tab
            headingsComponent={TabHeadingsVertical}
            vertical
            items={digitalOnboardingTabItems}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default CaseStudies;
