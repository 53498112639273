import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Row, Col, Container } from 'react-grid-system';
import { IoIosArrowDown } from 'react-icons/io';
import breakpoints from 'utils/breakpoints';
import TabHeadings from './TabHeadings';
import TabHeadingsVertical from './TabHeadingsVertical';

const Wrapper = styled(Container)`
  margin-top: 40px;
  @media screen and (min-width: ${breakpoints.md}) {
    margin-top: 0;
  }
`;
const VerticalWrapper = styled.div``;
const Header = styled.header`
  display: none;
  @media screen and (min-width: ${breakpoints.md}) {
    display: block;
  }
`;

const ContentWrap = styled.div`
  border: 1px solid #dbe6f3;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 0 15px;
  margin-bottom: 5px;

  @media screen and (min-width: ${breakpoints.md}) {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
  }
`;

const titleActiveStyle = css`
  border-bottom: 1px solid #dbe6f3;
  color: #0276f1;

  svg {
    transform: rotate(180deg);
  }
`;

const MobileTitle = styled.div`
  padding: 25px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 200ms linear;

  svg {
    transition: transform 200ms linear;
  }

  ${props => (props.active ? titleActiveStyle : null)};

  @media screen and (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const Content = styled.div`
  display: ${props => (props.mobileActive ? 'block' : 'none')};
  h4 {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    h4 {
      display: block;
    }
    display: ${props => (props.active ? 'block' : 'none')};
  }
`;

function Tab({ vertical, className, items }) {
  const [activeId, setActiveId] = useState(null);
  const activeItem = useMemo(() => {
    if (!activeId) {
      return items[0];
    }
    return items.find(item => item.id === activeId);
  }, [activeId, items]);
  const HeadingComponent = vertical ? TabHeadingsVertical : TabHeadings;
  const heading = (
    <Header>
      <HeadingComponent
        onChange={setActiveId}
        activeId={activeItem.id}
        items={items}
      />
    </Header>
  );

  if (vertical) {
    return (
      <VerticalWrapper className={className}>
        <Row align="start" className={className}>
          <Col md={6}>{heading}</Col>
          <Col md={6}>
            {items.map(item => (
              <ContentWrap key={item.title}>
                <MobileTitle
                  onClick={() => {
                    setActiveId(item.id === activeId ? null : item.id);
                  }}
                  active={item.id === activeId}
                >
                  {item.title}
                  <IoIosArrowDown />
                </MobileTitle>
                <Content
                  mobileActive={item.id === activeId}
                  active={item.id === activeItem.id}
                >
                  {item.content}
                </Content>
              </ContentWrap>
            ))}
          </Col>
        </Row>
      </VerticalWrapper>
    );
  }

  return (
    <Wrapper className={className}>
      {heading}
      {items.map(item => (
        <ContentWrap key={item.title}>
          <MobileTitle
            onClick={() => setActiveId(item.id === activeId ? null : item.id)}
            active={item.id === activeId}
          >
            {item.title}
            <IoIosArrowDown />
          </MobileTitle>
          <Content
            mobileActive={item.id === activeId}
            active={item.id === activeItem.id}
          >
            {item.content}
          </Content>
        </ContentWrap>
      ))}
    </Wrapper>
  );
}

Tab.defaultProps = {
  className: null,
  vertical: false,
};

Tab.propTypes = {
  vertical: PropTypes.bool,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default Tab;
