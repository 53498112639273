import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Heading from 'components/Heading';
import Spacer from 'components/Spacer';
import PolygonIcon from 'components/ContentIcon';
import { Row, Col, Visible } from 'react-grid-system';
import Card from 'components/Card';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.div``;
const CardWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.md}) {
    height: 100%;
  }
`;

const StyledRow = styled(Row)`
  margin-top: 40px;
  @media screen and (min-width: ${breakpoints.md}) {
    margin-top: 80px;
  }
`;

const StyledCard = styled(Card)`
  text-align: center;
  padding-left: 43px;
  padding-right: 43px;
`;

const CardText = styled.p`
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #1c2f45;
  margin: 0;
`;

const HeadingText = styled.div`
  font-weight: normal;

  strong {
    margin: 0 9px;
  }
`;

function WhyChooseUs({ className }) {
  return (
    <Wrapper className={className}>
      <Heading
        centered
        name="Mengapa memilih kami?"
        title={
          <HeadingText>
            <strong>40% lebih cepat</strong>
            &amp;
            <strong>10x lebih akurat</strong>
            dibandingkan dengan
            <br />
            rata rata verifikasi manusia
          </HeadingText>
        }
      />
      <StyledRow align="strech">
        <Col md={4} lg={3}>
          <CardWrapper>
            <StyledCard icon={<PolygonIcon type="Quickly" />}>
              <CardText>
                Memverifikasi aplikasi pinjaman dengan cepat dan
                mempercepat keseluruhan proses
              </CardText>
            </StyledCard>
          </CardWrapper>
          <Visible xs sm>
            <Spacer space="10px" />
          </Visible>
        </Col>
        <Col md={4} lg={3}>
          <CardWrapper>
            <StyledCard icon={<PolygonIcon type="Robot" />}>
              <CardText>
                Mengoptimalkan alur pengajuan pinjaman dengan mengurangi
                biaya operasional dan kemungkinan kesalahan manusia
              </CardText>
            </StyledCard>
          </CardWrapper>
          <Visible xs sm>
            <Spacer space="10px" />
          </Visible>
        </Col>
        <Col md={4} lg={3}>
          <CardWrapper>
            <StyledCard icon={<PolygonIcon type="Fast" />}>
              <CardText>
                Sebuah solusi yang cepat dan terukur dengan integrasi yang
                mudah dan tanpa masalah keamanan maupun privasi
              </CardText>
            </StyledCard>
          </CardWrapper>
          <Visible xs sm>
            <Spacer space="10px" />
          </Visible>
        </Col>
        <Col md={4} lg={3}>
          <CardWrapper>
            <StyledCard icon={<PolygonIcon type="DisbursedLoans" />}>
              <CardText>Memungkinkan proses kredit konsumer tanpa kertas</CardText>
            </StyledCard>
          </CardWrapper>
          <Visible xs sm>
            <Spacer space="10px" />
          </Visible>
        </Col>
      </StyledRow>
    </Wrapper>
  );
}

WhyChooseUs.defaultProps = {
  className: null,
};

WhyChooseUs.propTypes = {
  className: PropTypes.string,
};

export default WhyChooseUs;
