import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'components/Img';

function FaceIDImage(props) {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "products/trust-identity/TI-01.svg" }) {
        publicURL
      }
    }
  `);

  return <Img {...props} {...data.image} />;
}

export default FaceIDImage;
